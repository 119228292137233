import React from "react"
import { ContentContainer } from "../components/Container";
import { Footer } from "../components/Footer"
import { Main } from "../components/Main";
import { Menubar } from "../components/Menu"
import { Section } from "../components/Section";
import { Head as DefaultHead } from "../head";
import { graphql } from 'gatsby'

function Privacy() {
	return (
		<Main>
			<Menubar />
			<Section bg="bg-fl-turkis-dark" color="text-fl-text">
				<ContentContainer className="px-2 pb-12">
					<h1 className="text-3xl font-serif text-center text-fl-turkis-light">Privacy Policy</h1>
					<div className="mx-auto max-w-lg">
						<Title>Brief Summary</Title>
						<p>
							We focus on making great games, not collecting unnecessary data. If you don’t subscribe to our <b className="text-fl-white">optional</b> newsletter, no personally identifiable data will be stored or processed other than when it’s strictly necessary.
						</p>
						<p className="mt-4">
							We store and access data on your device only to allow the website to function, and we use an anonymous and cookie-free analytics service, we&nbsp;don’t know who you are.
						</p>
						<Title>Legal</Title>
						<Subtitle>1. Responsible for Data Processing</Subtitle>
						<p>
							The authority responsible for data collection and processing is Frameland Games e. U., Mexikoplatz 15/14, 1020 Austria.
						</p>
						<Subtitle>2. Encryption</Subtitle>
						<p>
							This website employs SSL/TLS encryption to protect data.
						</p>
						<Subtitle>3. Data Storage and Cookies</Subtitle>
						<Subtitle sub>3.1 Website Access</Subtitle>
						<p>
							While visiting this website you will transmit data (such as your IP address, browser, operating system and more) to our web hosting provider, <ExternalLink to="https://www.netlify.com">Netlify</ExternalLink>. This data is compiled in access logs and will be stored for 30 days to provide security and defense against malicious intent.
						</p>
						<Subtitle sub>3.2. Cookies</Subtitle>
						<p>
							We don’t use cookies.
						</p>
						<Subtitle sub>3.2. Local and Session Storage</Subtitle>
						<p>
							We use the browser’s session storage to ensure our website’s functionality. We will store how far you have scrolled on the page. Closing the browser window or tab will delete the data.
							<br />
							We also use local storage to ensure caching, store your language preference and to make your browsing experience more seamless.
							<br />
							This data is not personally identifiable and will not be stored on our web servers.
						</p>

						<Subtitle>4. Contact</Subtitle>
						<p>
							You can contact us via contact@frameland.net.
						</p>
						<p>
							If you send us an e-mail, we will receive your personal data (e. g. your e-mail address and, if applicable, your name). The data you send us will only be used and saved for the purpose of answering your request. After the final handling of your request, your data will be deleted — if there are no legal obligations for us to keep it.
						</p>

						<Subtitle>5. Tracking Services</Subtitle>
						<p>
							We use the web analytics software <ExternalLink to="https://microanalytics.io/">microanalytics</ExternalLink> to better understand user behavior and improve our website’s content. We will never know who you are based on the data we collect.
							<br />
							The following information is processed and stored by this service:
						</p>
						<ul className="list-disc ml-8 my-3">
							<li>The page you are visiting</li>
							<li>Date and time of your access</li>
							<li>The page you are visiting from (referrer)</li>
							<li>The browser you use</li>
							<li>The operating system you use</li>
							<li>Your device’s resolution</li>
							<li>The country you are visiting from</li>
						</ul>
						<p>
							You can view microanalytics’ privacy policy here: <ExternalLink to="https://microanalytics.io/pages/privacy">https://microanalytics.io/pages/privacy</ExternalLink>
						</p>
						<Subtitle>6. Newsletter Subscription</Subtitle>
						<p>
							If you choose to subscribe to our newsletter, personally identifiable data (i. e. your e-mail address) will be sent to, processed and saved by our third-party marketing tool, <ExternalLink to="https://mailchimp.com/">Mailchimp</ExternalLink>. We do not sell your e-mail address or forward it to any third parties except Mailchimp itself, where it will only be used for relevant communication about our games.
							<br />
							Mailchimp may store this data on servers outside the EU, primarily in the United States.
							<br />
							We will make no effort to combine this address with any other personally identifiable data.
							<br />
							To ensure authenticity, you will need to confirm your registration via double opt-in. If you have received any communication in error, please get in touch via contact@frameland.net.
							<br />
							By subscribing to our newsletter, you confirm that you give explicit and revocable consent to the terms laid out above. You can revoke consent at any time by unsubscribing, either by using the ‘unsubscribe’ link in the newsletter or by sending a deletion request to contact@frameland.net.
						</p>
					</div>
				</ContentContainer>
			</Section>
			<Footer />
		</Main>
	)
}

function Title({ children }) {
	return (
		<h2 className="text-lg uppercase tracking-wider text-fl-white text-center mb-2 mt-6">
			{children}
		</h2>
	)
}

function Subtitle({ children, sub = false }) {
	const marginTop = sub ? 'mt-0' : 'mt-4 text-lg';
	return (
		<h2 className={`text-md text-fl-white ${marginTop}`} >
			{children}
		</h2 >
	)
}

function ExternalLink({ to, children }) {
	return (
		<a href={to} target="_blank" rel="noreferrer" className="underline">
			{children}
		</a>
	)
}

export function Head() {
	return (
		<DefaultHead
			title="Frameland: Privacy"
			description="Frameland: Our privacy policy"
		/>
	)
}


export default Privacy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
